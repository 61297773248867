<template>
  <v-container class="welcome-news d-flex flex-column align-center justify-center fill-height">
    <v-card width="840" class="card-about">
      <v-card-title></v-card-title>
      <v-card-text class="text-center">
        <h1 class="mb-4">Hébergeur</h1>

        Le site est hébergé auprès de la société OVH dont les coordonnées sont<br><br>
        OVH Sarl<br>
        140 Quai du Sartel<br>
        59100 Roubaix<br>
        France<br>
        Téléphone: 0899 701 761<br>
        Site Internet: <b>www.ovh.com</b><br>
        <br>
        L'hébergeur dispose des coordonnées du gestionnaire du site, conformément aux dispositions de la loi française n°2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.

      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
name: "About"
}
</script>

<style scoped>

</style>